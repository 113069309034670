import Title from 'antd/lib/typography/Title';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.css';

export default () => {
	const navigate = useNavigate();
	return (
		<div className={styles.layout}>
			<Title>公寓管理系统</Title>
		</div>
	);
};
